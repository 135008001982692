














import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Tooltip extends Vue {
  @Prop({}) content!: string;
  @Prop({ default: null }) label!: string | null;

  private show = false;
}
